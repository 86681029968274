<template>
  <div>
    <ComHeader menuKey="about"></ComHeader>
    <!-- banner -->
    <div class="common-banner">
      <div class="container ui acenter">
        <img class="banner-icon animate__animated animate__backInLeft" src="@/assets/images/about-banner-icon.png" alt="">
        <div class="f1 animate__animated animate__backInDown">
          <p class="banner-title">中码科技</p>
          <p>中码科技是一家全国领先的权威数字身份核验服务运营商，专注于为各行业提供权威、可信、安全、便捷的合规身份认证及应用服务。</p>
        </div>
      </div>
    </div>
    <!-- main -->
    <div class="container">
      <div class="about-detail">
        <p>中码科技是一家全国领先的权威数字身份核验服务运营商，专注于为各行业提供权威、可信、安全、便捷的合规身份认证及应用服务。</p>
        <p>中码团队依托可信身份认证、电子取证、大数据和人工智能技术，凭借创新的实我风险核验系统、智能硬件及API服务产品体系，运用便捷的集成方式及精准的智能算法，专注于为党政机关、金融机构、互联网企业、活动展会、医院、学校、园区、社区等各行业提供“人防+技防+物防”的综合性解决方案，真正做到人、证、码三者合一。</p>
        <p>中码科技秉承以构建平安中国为核心，不断发展与创新，致力于成长为中国数字化治理服务的顶级解决方案服务运营商与社会安全核验专家，持续不断地为每个客户创造价值。</p>
      </div>
      <div class="about-item">
        <div class="title">产品生态</div>
        <div class="ecology-wrap">
          <div class="ecology-item hvr-grow">
            <img src="@/assets/images/about-ecology1.png" alt="">
            <p>硬件生态</p>
          </div>
          <div class="ecology-item hvr-grow">
            <img src="@/assets/images/about-ecology2.png" alt="">
            <p>实我核验系统生态</p>
          </div>
          <div class="ecology-item hvr-grow">
            <img src="@/assets/images/about-ecology3.png" alt="">
            <p>API服务生态</p>
          </div>
        </div>
      </div>
      <div class="about-item">
        <div class="title mt-80">应用场景</div>
        <div class="scene-wrap">
          <div class="scene-item hvr-rotate">
            <img src="@/assets/images/about-scene1.png" alt="">
            <p>银行</p>
          </div>
          <div class="scene-item hvr-rotate">
            <img src="@/assets/images/about-scene2.png" alt="">
            <p>酒店</p>
          </div>
          <div class="scene-item hvr-rotate">
            <img src="@/assets/images/about-scene3.png" alt="">
            <p>园区</p>
          </div>
          <div class="scene-item hvr-rotate">
            <img src="@/assets/images/about-scene4.png" alt="">
            <p>医院</p>
          </div>
          <div class="scene-item hvr-rotate">
            <img src="@/assets/images/about-scene5.png" alt="">
            <p>展会</p>
          </div>
          <div class="scene-item hvr-rotate">
            <img src="@/assets/images/about-scene6.png" alt="">
            <p>学校</p>
          </div>
        </div>
      </div>
      <div class="about-item">
        <div class="title mt-80">中码客户</div>
        <div class="custom-wrap">
          <div class="custom-item hvr-grow" v-for="(item,index) in customList" :key="index">
            <img :src="item.imgUrl" alt="">
            <p>{{item.title}}</p>
          </div>
        </div>
      </div>
    </div>
    <ComFooter></ComFooter>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ComHeader from "@/components/header.vue";
import ComFooter from "@/components/footer.vue";

export default defineComponent({
  components: {ComHeader,ComFooter},

  setup() {
    const customList = [
      {title:"成都市政府",imgUrl:require("@/assets/images/custom/icon-government.png")},
      {title:"宜宾市政府",imgUrl:require("@/assets/images/custom/icon-government.png")},

      {title:"中国建设银行",imgUrl:require("@/assets/images/custom/icon-bank-js.png")},
      {title:"广发银行",imgUrl:require("@/assets/images/custom/icon-bank-gf.png")},
      {title:"人保金融",imgUrl:require("@/assets/images/custom/icon-rbjr.png")},
      {title:"中银消费金融",imgUrl:require("@/assets/images/custom/icon-zyxfjr.png")},
      {title:"成都交子金控集团",imgUrl:require("@/assets/images/custom/icon-cdjzjkjt.png")},
      {title:"四川锦程消费金融",imgUrl:require("@/assets/images/custom/icon-scjcxfjr.png")},

      {title:"浙江电力集团",imgUrl:require("@/assets/images/custom/icon-zjdl.png")},
      {title:"京东方光电科技",imgUrl:require("@/assets/images/custom/icon-jdf.png")},
      {title:"富士康科技集团",imgUrl:require("@/assets/images/custom/icon-fsk.png")},
      {title:"一汽·大众",imgUrl:require("@/assets/images/custom/icon-dz.png")},
      {title:"北京比特威尔",imgUrl:require("@/assets/images/custom/icon-bj.png")},

      {title:"四川航天信息有限公司",imgUrl:require("@/assets/images/custom/icon-scht.png")},
      {title:"全友家私",imgUrl:require("@/assets/images/custom/icon-qyjs.png")},

      {title:"九洲集团",imgUrl:require("@/assets/images/custom/icon-jz.png")},
    ]

    return {
      customList
    };
  },

});
</script>

<style lang="less" scoped>
.common-banner{
  background: url("../../assets/images/common-banner-bg.png") no-repeat;
  background-size: 100% 100%;
  .banner-icon{
    width: 188px;
    height: 179px;
    margin-right: 50px;
  }
}
.mt-80{
  margin-top: 80px;
}
.about-detail{
  padding: 60px 0;
  color: #142D70;
  text-indent: 2em;
  font-size: 16px;
  p{
    margin-bottom: 10px;
  }
}
.about-item{
  .title{
    color: #142D70;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 80px;
    &::after{
      content: "";
      display: block;
      width: 200px;
      height: 4px;
      background-color: #142D70;
      margin: 10px auto;
    }
  }
}
.ecology-wrap{
  display: flex;
  justify-content: space-between;
  width: 1000px;
  margin: 0 auto;
  .ecology-item{
    text-align: center;
    font-weight: 600;
    color: #142D70;
    img{
      width: 200px;
      height: 200px;
      margin-bottom: 40px;
    }
  }
}
.scene-wrap{
  display: flex;
  justify-content: space-between;
  .scene-item{
    width: 180px;
    border-radius: 8px;
    border: 1px solid rgba(180, 180, 180, 0.45);
    text-align: center;
    font-weight: 600;
    color: #142D70;
    img{
      width: 180px;
      height: 194px;
      display: block;
      margin-bottom: 10px;
      border-radius: 8px 8px 0 0;
    }
  }
}
.custom-wrap{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  .custom-item{
    text-align: center;
    width: 16.6%;
    margin-bottom: 20px;
    color: #142D70;
    img{
      width: 100px;
    }
  }
}
</style>
<style lang="less" scoped>
.mobile{
  .common-banner{
    height: 300px;
    padding:50px 20px 0;
    display: flex;
    align-items: center;
    .banner-icon{
      width: 121px;
      height: auto;
      margin-right: 50px;
    }
  }
  .about-detail{
    padding: 20px;
  }
  .about-item{
    .title{
      margin-bottom: 20px;
      &.mt-80{
        margin-top: 20px;
      }
    }
    .ecology-wrap{
      width: 100%;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .ecology-item{
        margin: 0 20px;
        img{
          margin-bottom: 10px;
          width: 150px;
          height: 150px;
        }
      }
    }
    .scene-wrap{
      padding: 0 20px;
      flex-wrap: wrap;
      .scene-item{
        width: 48%;
        margin-bottom: 10px;
        img{
          width: 100%;
          height: auto;
        }
      }
    }
    .custom-wrap{
      padding: 0 10px;
      justify-content: space-between;
      .custom-item{
        width: 33%;
        margin-bottom: 0;
      }
    }
  }
}
</style>